import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

// eslint-disable-next-line monorepo/forbid-crate-imports
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import type { AsyncHookResult, Experiments } from '@xing-com/hub';
import { asyncResult, ExperimentsContext, useVisitorId } from '@xing-com/hub';

import {
  useExperimentsQuery,
  useSuspenseExperimentsQuery,
} from './experiments-query';
import { useExperiment, useExperimentTrackingData } from './hooks';

const context = { useExperiment, useExperimentTrackingData, useExperiments };

const InternalContext = createContext<AsyncHookResult<Experiments>>({
  loading: true,
});

export const SplitkitStaticModule: FC<
  PropsWithChildren<{ experiments: AsyncHookResult<Experiments> }>
> = ({ children, experiments }) => {
  return (
    <InternalContext.Provider value={experiments}>
      <ExperimentsContext.Provider value={context}>
        {children}
      </ExperimentsContext.Provider>
    </InternalContext.Provider>
  );
};

export const SplitkitModule: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { useStreaming = false } = useRuntimeConfig();

  const visitorId = useVisitorId();
  const experiments = useStreaming
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      asyncResult(useSuspenseExperimentsQuery(visitorId))
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useExperimentsQuery(visitorId);

  return (
    <SplitkitStaticModule experiments={experiments}>
      {children}
    </SplitkitStaticModule>
  );
};

export function useExperiments(): AsyncHookResult<Experiments> {
  return useContext(InternalContext);
}
