import styled, { css } from 'styled-components';

import {
  zIndexElevatedLayer5,
  mediaTopBarVisible,
  mediaTopBarHidden,
} from '@xing-com/layout-tokens';
import { Logo } from '@xing-com/platform-layout-logo';
import {
  xdlColorBackground,
  scale010,
  scale170,
  xdlColorBorderSoft,
  scale210,
  spaceL,
  spaceXXL,
  space3XL,
  spaceXL,
} from '@xing-com/tokens';

export const StyledProductsMenuWrapper = styled.div`
  @media (max-width: 1112px) {
    display: none;
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spaceL};

  @media ${mediaTopBarHidden} {
    gap: ${spaceXL};
  }

  @media (min-width: 1428px) {
    gap: ${spaceXXL};
  }

  @media (min-width: 1572px) {
    gap: ${space3XL};
  }
`;

export const StyledOnlyOnBigScreenContainer = styled(StyledActionsContainer)`
  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

type StyledHeaderPlaceboProps = {
  $isLoggedOut: boolean;
};

export const StyledHeaderPlacebo = styled.div<StyledHeaderPlaceboProps>`
  height: ${scale210};

  @media ${mediaTopBarVisible} {
    height: ${scale170};
  }

  @media ${mediaTopBarVisible} {
    ${({ $isLoggedOut }) =>
      $isLoggedOut
        ? css``
        : css`
            display: none;
          `}
  }

  @media print {
    display: none;
  }
`;

export const StyledHeaderWrapper = styled.header`
  background-color: ${xdlColorBackground};
  border-bottom: ${scale010} solid ${xdlColorBorderSoft};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: calc(${zIndexElevatedLayer5} - 50);

  @media ${mediaTopBarVisible} {
    height: calc(${scale170});
  }
`;

export const StyledHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  flex-wrap: nowrap;

  height: ${scale210};
  @media ${mediaTopBarVisible} {
    height: ${scale170};
  }
`;

export const StyledLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
`;

export const StyledLogo = styled(Logo).attrs({ variant: 'duo', size: 96 })`
  display: none;
  margin-right: ${spaceXXL};

  @media (min-width: 1268px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1356px) {
    margin-right: ${space3XL};
  }
`;

export const StyledButterfly = styled(Logo).attrs({
  butterfly: true,
  size: 28,
})`
  display: block;
  margin-right: ${spaceL};
  @media (min-width: 1268px) {
    display: none;
  }
`;
