/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';
const IconAI = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    data-xds="IconAI"
    role="presentation"
    {...props}
  >
    <path
      fill="currentColor"
      d="m17.5 12 .81 2.58a2.86 2.86 0 0 0 2.12 2.12l2.57.8-2.58.81a2.86 2.86 0 0 0-2.12 2.12L17.5 23l-.81-2.58a2.86 2.86 0 0 0-2.12-2.12L12 17.5l2.58-.81a2.86 2.86 0 0 0 2.12-2.12zM10 18.67l-2-4.96c-.31-.77-.94-1.4-1.71-1.71l-4.96-2 4.96-2c.77-.31 1.4-.94 1.71-1.71l2-4.96 2 4.96c.31.77.94 1.4 1.71 1.71l4.96 2-4.96 2c-.77.31-1.4.94-1.71 1.71zM6.67 10l.37.15c1.27.51 2.3 1.54 2.82 2.82l.14.36.15-.37c.51-1.27 1.54-2.3 2.82-2.82l.36-.14-.37-.15a5.13 5.13 0 0 1-2.82-2.82L10 6.67l-.15.37a5.09 5.09 0 0 1-2.82 2.81z"
    />
  </svg>
);
export default IconAI;
