import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useAppliedTheme } from '@xing-com/hub';

import { DarkColorscheme } from './dark-colorscheme';
import { LightColorscheme } from './light-colorscheme';
import { useUsercentricsEvent } from './use-user-centrics-event';
import { getCookie, setCookie } from './utils';

type CookieConsentUsercentricsProps = {
  id?: string;
  disableBanner?: boolean;
};

const defaultId = 'Tr_3B1GDkkB1q2';

const shouldSkipBanner = (disableBanner?: boolean): boolean => {
  if (disableBanner) {
    return true;
  }
  const isBrowserEnvironment = typeof window !== 'undefined';
  const host = isBrowserEnvironment ? window.location.host : '';
  const isLocalhostOrEnv =
    /[k]?env\.xing\.com|localhost:|^192\.168|http:\/\/application$/.test(host);
  const urlContainsPercy =
    isBrowserEnvironment && window.location.href.includes('percy');

  if (isBrowserEnvironment && (urlContainsPercy || isLocalhostOrEnv)) {
    return true;
  }
  if (!isBrowserEnvironment) {
    return true;
  }
  return false;
};

export const CookieConsentUsercentrics: React.FC<
  CookieConsentUsercentricsProps
> = ({ id = defaultId, disableBanner }) => {
  // set cookie for old consent if not present to prevent it from opening in legacy apps
  useEffect(() => {
    const COOKIE_NAME = 'userConsent';
    const COOKIE_VALUE = JSON.stringify({
      marketing: false,
      version: '5',
    });
    const COOKIE_EXPIRY_DAYS = 365;
    const existingCookie = getCookie(COOKIE_NAME);

    if (!existingCookie) {
      // Set cookie if not present
      setCookie(COOKIE_NAME, COOKIE_VALUE, COOKIE_EXPIRY_DAYS);
    }
  }, []);

  useUsercentricsEvent();

  const appliedTheme = useAppliedTheme();
  const isDarkMode = appliedTheme === 'dark';

  if (shouldSkipBanner(disableBanner)) {
    return null;
  }

  return (
    <>
      {isDarkMode ? <DarkColorscheme /> : <LightColorscheme />}
      <Helmet>
        <script>
          {`window.UC_UI_DOMAINS = {
            crossDomainConsentSharingIFrame: 'https://www.xing.com/cross-domain-bridge.html'
          };`}
        </script>

        <script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          async
          data-draft="true"
          data-settings-id={id}
        />
      </Helmet>
    </>
  );
};
