import ttt from '@xing-com/ticktricktrack';

const hostname =
  (globalThis.location && globalThis.location.hostname) || '(none)';

const defaultEnv = 'preview';

const ciHostRegex = /ci-docker[0-9]+\.env\.xing\.com/;
const sandboxUrl = ciHostRegex.test(hostname)
  ? '/mocked-stm-sandbox'
  : undefined;

const envs: [RegExp, string][] = [
  [/^localhost$/, 'development'],
  [/^lebenslauf.com$/, 'production'],
  [/^anschreiben.com$/, 'production'],
  [/^www.xing.com$/, 'production'],
  [/^login.xing.com$/, 'production'],
  [/.kenv.xing.com$/, 'xingbox'],
  [/.oenv.xing.com$/, 'xingbox'],
];

const envEntry = envs.find(([regEx]) => regEx.test(hostname));
const env = envEntry ? envEntry[1] : defaultEnv;

export const initTracking = (): typeof ttt => {
  ttt.init(env, { fetchTrackingData: true, sandboxUrl });
  if (typeof window !== 'undefined') Object.assign(window, { ttt });
  return ttt;
};
