import styled, { css } from 'styled-components';

import {
  mediaConfined,
  mediaNavExtended,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import { Me } from '@xing-com/platform-layout-me';
import { spaceM, spaceL, spaceXL, xdlColorBackground } from '@xing-com/tokens';

export type SideBarMeProps = {
  collapsed?: boolean;
};

export const SideBarMe = styled(Me)<SideBarMeProps>`
  && {
    ${({ collapsed }) => {
      return collapsed
        ? css`
            @media ${mediaConfined} {
              display: block;
              margin: ${spaceL} 0;
            }
            @media ${mediaNavExtended} {
              display: none;
            }
          `
        : css`
            @media ${mediaConfined} {
              display: none;
            }
            @media ${mediaNavExtended} {
              display: block;
              margin: ${spaceXL} ${spaceM};
            }
          `;
    }}
  }
`;

export const Nav = styled.nav`
  /* Show VerticalNavigation component on Tablet size upwards */
  display: none;

  @media ${mediaWideNavless} {
    display: block;
    width: 68px;
  }

  @media ${mediaNavExtended} {
    margin-left: calc(-1 * ${spaceM});
    width: 228px;
  }
`;

export const VertNavUl = styled.ul`
  background-color: ${xdlColorBackground};
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding-left: 0;
`;
