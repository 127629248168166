import type { ComponentProps, FC } from 'react';
import styled from 'styled-components';

import { useMetaConfig } from '@xing-com/hub';
import { mediaConfined } from '@xing-com/layout-tokens';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';
import { spaceM, spaceL, spaceXL, spaceXXL } from '@xing-com/tokens';
import { addParams } from '@xing-com/xing-tracking';

import { LinkItem } from './link-item';

type MoreMenuListProps = {
  closeModal: () => void;
};

type MoreMenuItemProps = {
  closeModal: () => void;
  link: {
    key: string;
    href: string;
    title: string;
    icon: ComponentProps<typeof LinkItem>['icon'];
    color: ComponentProps<typeof LinkItem>['color'];
    tracking: Record<string, string>;
  };
};

const StyledMoreMenuList = styled.div`
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: ${spaceXL} ${spaceXXL} 0;

  @media ${mediaConfined} {
    padding: 0 ${spaceM} ${spaceL};
  }
`;

const MoreMenuItem: FC<MoreMenuItemProps> = ({ closeModal, link }) => {
  const { key, tracking, ...linkProps } = link;

  const completeTrackingUrl = addParams(linkProps.href, tracking);

  return (
    <LinkItem
      data-qa={`MoreMenuDropdown-list-item-${key}`}
      closeModal={closeModal}
      {...linkProps}
      href={completeTrackingUrl}
    />
  );
};

const resolvers = {
  ...commonMetaRendererResolvers,
  MoreMenuItem,
};

export type MoreMenuConfig = ConfigForResolvers<typeof resolvers>;

export const MoreMenuList: FC<MoreMenuListProps> = ({
  closeModal,
}: MoreMenuListProps) => {
  const { data } = useMetaConfig();

  return (
    <StyledMoreMenuList>
      {data?.navigation?.moreMenuDropdown && (
        <MetaRenderer
          config={data.navigation.moreMenuDropdown}
          resolvers={resolvers}
          closeModal={closeModal}
        />
      )}
    </StyledMoreMenuList>
  );
};
