import type { FC } from 'react';
import { useIntl } from 'react-intl';

import { useLoginState, useFeatures } from '@xing-com/hub';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCompanies,
  IconCompaniesFilled,
  IconContacts,
  IconContactsFilled,
  IconJobs,
  IconJobsFilled,
  IconMessages,
  IconMessagesFilled,
  IconNews,
  IconNewsFilled,
  IconNotificationFilled,
} from '@xing-com/icons';
import { Section } from '@xing-com/layout-primitives';
import {
  ProductsMenuWithItemsLoggedOut,
  ProductsMenuWithItemsLoggedIn,
} from '@xing-com/platform-layout-products-menu';

import { ActivityCenterButton } from './activity-center-button/activity-center-button';
import {
  StyledActionsContainer,
  StyledHeader,
  StyledHeaderPlacebo,
  StyledHeaderWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledButterfly,
  StyledOnlyOnBigScreenContainer,
  StyledProductsMenuWrapper,
} from './header.styles';
import { LoginButton } from './login-button/login-button';
import { MeMenuDropdown } from './me-menu-dropdown';
import { NavItemHeader } from './nav-item-header';
import { NotificationCenterIcon } from './notification-center-icon';
import { SearchDropdown } from './search-dropdown';
import { Boneyard } from './upsell-boneyard/boneyard';

const HeaderLogo: FC = () => {
  return (
    <StyledLogoWrapper>
      <StyledLogo dataTestId="logo" />
      <StyledButterfly dataTestId="logoMobile" />
    </StyledLogoWrapper>
  );
};

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export type HeaderProps = {
  className?: string;
  loginLink?: string;
  loginTracking?: { sc_o: string };
};

export const Header: React.FC<HeaderProps> = ({ className, loginLink }) => {
  const isLoggedOut = useLoginState() === 'LOGGED_OUT';
  const { showUpsellHint, showJobSeekerBoneyardUpsellHint } =
    useFeatures()?.data || {};

  const { formatMessage } = useIntl();

  return (
    <StyledHeaderPlacebo $isLoggedOut={isLoggedOut} className={className}>
      <StyledHeaderWrapper>
        <Section noBlockPadding>
          <StyledHeader>
            <HeaderLogo />
            <StyledActionsContainer data-testid="header-navigation" as="nav">
              {isLoggedOut ? (
                <>
                  <StyledOnlyOnBigScreenContainer>
                    <NavItemHeader
                      activeItemValues={['jobs']}
                      icon={IconJobs}
                      activeIcon={IconJobsFilled}
                      title={formatMessage({ id: 'MAINNAV_JOBS_FIND' })}
                      href="https://www.xing.com/jobs"
                      tracking={{
                        sc_o: 'navigation_find_jobs',
                      }}
                    />

                    <NavItemHeader
                      activeItemValues={['companies']}
                      icon={IconCompanies}
                      activeIcon={IconCompaniesFilled}
                      title={formatMessage({ id: 'MAINNAV_COMPANIES' })}
                      href="https://www.xing.com/companies"
                      tracking={{ sc_o: 'navigation_companies' }}
                    />
                    <NavItemHeader
                      icon={IconContacts}
                      activeIcon={IconContactsFilled}
                      title={formatMessage({ id: 'MAINNAV_NETWORK' })}
                      href="https://www.xing.com/network"
                      activeItemValues={['members']}
                      tracking={{
                        sc_o: 'navigation_network',
                      }}
                    />
                    <NavItemHeader
                      icon={IconNews}
                      activeIcon={IconNewsFilled}
                      title={formatMessage({ id: 'MAINNAV_INSIGHTS' })}
                      href="https://www.xing.com/insights"
                      activeItemValues={['insights']}
                      tracking={{
                        sc_o: 'navigation_insights',
                      }}
                    />
                  </StyledOnlyOnBigScreenContainer>
                  <ActivityCenterButton
                    title={formatMessage({ id: 'MAINNAV_ACTIVITY_CENTER' })}
                    href="https://www.xing.com/activity?sc_o=navigation_activity"
                  />

                  <LoginButton loginLink={loginLink} />
                  <StyledOnlyOnBigScreenContainer>
                    <ProductsMenuWithItemsLoggedOut />
                  </StyledOnlyOnBigScreenContainer>
                </>
              ) : (
                <>
                  <NavItemHeader
                    activeItemValues={['jobs']}
                    icon={IconJobs}
                    activeIcon={IconJobsFilled}
                    title={formatMessage({ id: 'MAINNAV_JOBS_FIND' })}
                    href="/jobs/find"
                    tracking={{ sc_o: 'navigation_find_jobs' }}
                  />
                  <NavItemHeader
                    activeItemValues={['my-jobs']}
                    icon={IconBookmark}
                    activeIcon={IconBookmarkFilled}
                    title={formatMessage({ id: 'MAINNAV_MYJOBS' })}
                    href="/jobs/my-jobs"
                    tracking={{
                      sc_o: 'navigation_your_jobs',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_jobs',
                        NAVIGATION_BADGE_NO_BADGE,
                      ],
                    }}
                  />
                  <NavItemHeader
                    icon={IconContacts}
                    activeIcon={IconContactsFilled}
                    title={formatMessage({ id: 'MAINNAV_NETWORK' })}
                    href="/network"
                    activeItemValues={['members']}
                    tracking={{
                      sc_o: 'navigation_network',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_network',
                        NAVIGATION_BADGE_NO_BADGE,
                      ],
                    }}
                  />
                  <NavItemHeader
                    icon={IconNews}
                    activeIcon={IconNewsFilled}
                    title={formatMessage({ id: 'MAINNAV_INSIGHTS' })}
                    href="/discover/updates"
                    activeItemValues={['disco']}
                    tracking={{
                      sc_o: 'navigation_insights',
                      sc_o_PropActionOrigin: NAVIGATION_BADGE_NO_BADGE,
                    }}
                  />
                  <NavItemHeader
                    icon={IconMessages}
                    activeIcon={IconMessagesFilled}
                    title={formatMessage({ id: 'MAINNAV_MESSAGES' })}
                    href="/chats"
                    activeItemValues={['messages']}
                    notificationKey="unreadChats"
                    tracking={{
                      sc_o: 'navigation_messages',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_messages',
                        NAVIGATION_BADGE_NO_BADGE,
                      ],
                    }}
                  />
                  <NavItemHeader
                    icon={NotificationCenterIcon}
                    activeIcon={IconNotificationFilled}
                    provideIconContext
                    activeItemValues={['supi']}
                    notificationKey="ncWeb"
                    href="/notifications"
                    title={formatMessage({ id: 'NOTIFICATION_CENTER_LINK' })}
                    tracking={{
                      sc_o: 'navigation_notifications',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_notifications',
                        NAVIGATION_BADGE_NO_BADGE,
                      ],
                    }}
                  />

                  <SearchDropdown />

                  <MeMenuDropdown />

                  {!showUpsellHint ? null : showJobSeekerBoneyardUpsellHint ? (
                    <Boneyard
                      dataTestId="upsell-button"
                      size="small"
                      variant="primary"
                      href="/purchase/projobs?reagent=uplt_277&sc_o=navigation_projobs_cta"
                    >
                      {formatMessage({
                        id: 'FRAME_HEADER_UPSELL_PROJOBS',
                      })}
                    </Boneyard>
                  ) : (
                    <Boneyard
                      dataTestId="upsell-button"
                      size="small"
                      variant="primary"
                      href="/purchase/premium?reagent=uplt_95&sc_o=navigation_premium_cta"
                    >
                      {formatMessage({
                        id: 'FRAME_HEADER_UPSELL_PREMIUM',
                      })}
                    </Boneyard>
                  )}

                  <StyledProductsMenuWrapper>
                    <ProductsMenuWithItemsLoggedIn />
                  </StyledProductsMenuWrapper>
                </>
              )}
            </StyledActionsContainer>
          </StyledHeader>
        </Section>
      </StyledHeaderWrapper>
    </StyledHeaderPlacebo>
  );
};
