import type { FC, FunctionComponent } from 'react';

import { useActiveItem, useNotifications } from '@xing-com/hub';
import { addParamsWithConditions, eitherOr } from '@xing-com/xing-tracking';

import { IconComponent, IconComponentContextProvider } from './icon-component';
import {
  NotificationCenterButton,
  StyledLink,
} from './notification-center.styles';

type NotificationCenterProps = {
  activeItemKey: string;
  notificationKey: string;
  icon: FunctionComponent;
  activeIcon: FunctionComponent;
  href: string;
  title: string;
  tracking: Record<string, string | [string, string]>;
  activeItemValues?: string[];
};

export const NotificationCenter: FC<NotificationCenterProps> = ({
  activeItemKey,
  activeItemValues,
  notificationKey,
  icon,
  activeIcon,
  href,
  title,
  tracking,
}) => {
  const activeItem = useActiveItem();
  const notifications = useNotifications();
  const notificationCount: number = notifications?.[notificationKey] || 0;

  const isActive =
    activeItem && activeItemValues
      ? activeItemValues.includes(activeItem)
      : activeItemKey === activeItem;

  const trackingConditions = {
    sc_o_PropActionOrigin: eitherOr(notificationCount),
  };

  const completeTrackingURL = addParamsWithConditions(
    href,
    tracking,
    trackingConditions
  );

  return (
    <IconComponentContextProvider
      value={{ isActive, icon, activeIcon, count: notificationCount }}
    >
      <StyledLink to={completeTrackingURL}>
        <NotificationCenterButton
          aria-label={title}
          icon={IconComponent}
          size="medium"
        >
          {title}
        </NotificationCenterButton>
      </StyledLink>
    </IconComponentContextProvider>
  );
};
