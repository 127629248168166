import type * as React from 'react';

import { Button } from '@xing-com/button';
import { useFeatureSwitch, useMetaConfig } from '@xing-com/hub';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';

import {
  ActionsContainer,
  StyledCenterContainer,
  StyledHeaderPlacebo,
  StyledHeaderWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledUpsellArea as UpsellArea,
} from './header-logged-in.styles';
import { NotificationCenter } from './notification-center';
import { SearchDropdown } from './search-dropdown';
import { SettingsDropdownX3 } from './settings-dropdown';

type LogoProps = {
  homeLink?: string;
};

const HeaderLoggedInLogo: React.FC<LogoProps> = ({ homeLink }) => {
  return (
    <StyledLogoWrapper>
      <StyledLogo
        variant="duo"
        size={96}
        homeLink={homeLink}
        dataTestId="logo"
      />
    </StyledLogoWrapper>
  );
};
const headerLoggedInResolvers = {
  Button,
  UpsellArea,
  NotificationCenter,
  SettingsDropdownX3,
  SearchDropdown,
};

const resolvers = {
  ...commonMetaRendererResolvers,
  ...headerLoggedInResolvers,
};

export type HeaderLoggedInConfig = ConfigForResolvers<typeof resolvers>;

export type HeaderLoggedInProps = {
  className?: string;
  homeLink?: string;
};

export const HeaderLoggedIn: React.FC<HeaderLoggedInProps> = ({
  className,
  homeLink,
}) => {
  const { data } = useMetaConfig();
  const isVerticalNavEnabled = useFeatureSwitch('nav_update_mid_2024');

  return (
    <StyledHeaderPlacebo className={className}>
      {!isVerticalNavEnabled ? (
        <StyledHeaderWrapper>
          <StyledCenterContainer>
            <HeaderLoggedInLogo homeLink={homeLink} />

            <ActionsContainer>
              {data?.navigation?.headerLoggedIn && (
                <MetaRenderer
                  config={data.navigation.headerLoggedIn}
                  resolvers={resolvers}
                />
              )}
            </ActionsContainer>
          </StyledCenterContainer>
        </StyledHeaderWrapper>
      ) : null}
    </StyledHeaderPlacebo>
  );
};
