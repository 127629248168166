import {
  type NWTEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';

export type NwtTrackParams = {
  event: string;
  context?: string;
  element?: string;
  elementDetail?: string;
  itemUrn?: string;
  page?: string;
};

type LoginTrackingType = {
  trackPageView: () => void;
  trackInteraction: (params: NwtTrackParams) => void;
};

export const useActivityCenterTracking = (): LoginTrackingType => {
  const { track } = useTracking<NWTEvent>();

  const trackPageView = (): void => {
    const additionalNWTProps: Record<string, unknown> = {};

    track({
      type: 'nwt',
      event: 'viewed_screen',
      eventSchema: 'basic',
      sentBy: 'activitycenter',
      page: 'activitycenter/activity',
      ...additionalNWTProps,
    });
  };

  const trackInteraction = ({
    event,
    context,
    element,
    elementDetail,
    itemUrn,
    page,
  }: NwtTrackParams): void => {
    track({
      type: 'nwt',
      event,
      context,
      eventSchema: 'basic',
      sentBy: 'activitycenter',
      element,
      elementDetail,
      itemUrn,
      page,
    });
  };

  return {
    trackPageView,
    trackInteraction,
  };
};
