import type * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import {
  useIsBottomBarEnabled,
  useIsBrowserBarOpen,
  useLoginState,
} from '@xing-com/hub';
import {
  IconBookmark,
  IconBookmarkFilled,
  IconCompanies,
  IconCompaniesFilled,
  IconContacts,
  IconContactsFilled,
  IconJobs,
  IconJobsFilled,
  IconNews,
  IconNewsFilled,
  IconNotificationCenter,
  IconNotificationFilled,
} from '@xing-com/icons';
import {
  mediaSmallOrTiny,
  mediaWideNavless,
  zIndexStickyLayer3,
} from '@xing-com/layout-tokens';
import {
  xdlColorBackground,
  xdlColorBorderSoft,
  scale180,
} from '@xing-com/tokens';

import { MainListItem } from './main-list';

const NavList = styled.ul`
  display: flex;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Nav = styled.nav`
  text-align: center;
  background-color: ${xdlColorBackground};
  border-top: 1px solid ${xdlColorBorderSoft};
  height: 100%;
  width: 100%;
`;

type ContainerProps = {
  $hide?: boolean;
};

const Container = styled.div<ContainerProps>`
  bottom: ${({ $hide }) => ($hide ? `-${scale180}` : 0)};
  height: ${scale180};
  left: 0;
  position: fixed;
  right: 0;
  transition: 150ms cubic-bezier(0.2, 0.2, 0.2, 1);
  transition-property: bottom;
  z-index: ${zIndexStickyLayer3};

  @media print {
    display: none;
  }
`;

const StyledBottomBarPlacebo = styled.div`
  @media ${mediaSmallOrTiny} {
    height: ${scale180};
  }
  @media ${mediaWideNavless} {
    display: none;
  }
`;

export const BottomBar: React.FC = () => {
  const { formatMessage } = useIntl();
  const isLoggedOut = useLoginState() === 'LOGGED_OUT';

  const isBrowserBarOpen = useIsBrowserBarOpen();
  const isBottomBarEnabled = useIsBottomBarEnabled();

  return isBottomBarEnabled ? (
    <StyledBottomBarPlacebo>
      <Container data-testid="BottomNav" $hide={!isBrowserBarOpen}>
        <Nav key="main">
          <NavList data-testid={isLoggedOut ? 'MainListLoggedOut' : 'MainList'}>
            <MainListItem
              item={{
                key: 'jobs',
                icon: IconJobs,
                activeIcon: IconJobsFilled,
                title: formatMessage({ id: 'MAINNAV_JOBS_FIND' }),
                href: isLoggedOut ? 'https://www.xing.com/' : '/jobs/find',
                tracking: isLoggedOut
                  ? undefined
                  : {
                      sc_o: 'navigation_find_jobs',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_find_jobs',
                        'navigation_badge_no_badge',
                      ],
                    },
              }}
            />

            {isLoggedOut ? (
              <MainListItem
                item={{
                  key: 'companies',
                  icon: IconCompanies,
                  activeIcon: IconCompaniesFilled,
                  href: 'https://www.xing.com/companies',
                  title: formatMessage({ id: 'MAINNAV_COMPANIES' }),
                }}
              />
            ) : (
              <MainListItem
                item={{
                  key: 'my-jobs',
                  icon: IconBookmark,
                  activeIcon: IconBookmarkFilled,
                  title: formatMessage({ id: 'BOTTOM_BAR_YOUR_JOBS_LABEL' }),
                  href: '/jobs/my-jobs',
                  tracking: {
                    sc_o: 'navigation_your_jobs',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_your_jobs',
                      'navigation_badge_no_badge',
                    ],
                  },
                }}
              />
            )}

            <MainListItem
              item={{
                key: 'contacts',
                icon: IconContacts,
                activeIcon: IconContactsFilled,
                title: formatMessage({ id: 'MAINNAV_NETWORK' }),
                activeItemValues: ['members'],
                href: 'https://www.xing.com/network',
                tracking: isLoggedOut
                  ? undefined
                  : {
                      sc_o: 'navigation_network',
                      sc_o_PropActionOrigin: [
                        'navigation_badge_network',
                        'navigation_badge_no_badge',
                      ],
                    },
              }}
            />

            <MainListItem
              item={{
                key: 'disco',
                icon: IconNews,
                activeItemValues: ['insights', 'disco'],
                activeIcon: IconNewsFilled,
                title: formatMessage({ id: 'MAINNAV_INSIGHTS' }),
                href: isLoggedOut
                  ? 'https://www.xing.com/insights'
                  : '/discover/updates',
                tracking: {
                  sc_o: 'navigation_insights',
                  sc_o_PropActionOrigin: 'navigation_badge_no_badge',
                },
              }}
            />

            {isLoggedOut ? null : (
              <MainListItem
                item={{
                  key: 'supi',
                  icon: IconNotificationCenter,
                  activeIcon: IconNotificationFilled,
                  title: formatMessage({
                    id: 'BOTTOM_BAR_NOTIFICATIONS_LABEL',
                  }),
                  href: '/notifications',
                  notificationKey: 'ncWeb',
                  tracking: {
                    sc_o: 'navigation_notifications',
                    sc_o_PropActionOrigin: [
                      'navigation_badge_notifications',
                      'navigation_badge_no_badge',
                    ],
                  },
                }}
              />
            )}
          </NavList>
        </Nav>
      </Container>
    </StyledBottomBarPlacebo>
  ) : null;
};
