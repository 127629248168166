import type { VFC } from 'react';
import * as React from 'react';

import { useMetaConfig, useViewerData } from '@xing-com/hub';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';
import type { MeProps } from '@xing-com/platform-layout-me';

import { MoreMenu as VertMoreMenu } from './more-menu';
import {
  NavItem as VertNavItem,
  NavItemPlaceholder,
} from './nav-list/nav-item';
import { SideBarMe, VertNavUl, Nav } from './side-bar.styles';

type VertNavMeProps = Pick<
  MeProps,
  'tracking' | 'href' | 'hasProfileProgressIndicator'
>;

const VertNavMe: VFC<VertNavMeProps> = ({
  href,
  tracking,
  hasProfileProgressIndicator,
}) => {
  return (
    <React.Fragment>
      <SideBarMe
        hasProfileProgressIndicator={hasProfileProgressIndicator}
        href={href}
        tracking={tracking}
      />
      <SideBarMe
        collapsed
        hasProfileProgressIndicator={hasProfileProgressIndicator}
        href={href}
        tracking={tracking}
      />
    </React.Fragment>
  );
};

const sideBarResolvers = {
  VertNavItem,
  VertNavMe,
  VertNavUl,
  VertMoreMenu,
};

const resolvers = {
  ...commonMetaRendererResolvers,
  ...sideBarResolvers,
};

export type VerticalNavigationConfig = ConfigForResolvers<typeof resolvers>;

const arrayOfN = (n: number): number[] => new Array(n).fill(0).map((_, i) => i);

const placeholders = (
  <VertNavUl>
    {arrayOfN(12).map((i) => (
      <NavItemPlaceholder key={i} />
    ))}
  </VertNavUl>
);

export const SideBar: React.FC = () => {
  const { data } = useMetaConfig();
  const viewerData = useViewerData();
  const hasUser = !viewerData.loading && viewerData.data?.user?.id;

  return (
    <Nav data-qa="frame-vnav">
      {hasUser && data ? (
        <MetaRenderer
          config={data.navigation.verticalNavigation}
          resolvers={resolvers}
        />
      ) : (
        placeholders
      )}
    </Nav>
  );
};
