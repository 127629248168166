import styled from 'styled-components';

import { SkeletonRectangle } from '@xing-com/skeleton';
import { cornerRadiusL, spaceXL } from '@xing-com/tokens';

const StyledSkeletonRectangle = styled(SkeletonRectangle)`
  max-width: 596px;
  margin: ${spaceXL} auto;
`;

export const LoadingSkeleton: React.FC = () => {
  const skeletonHeights = ['200px', '200px', '124px', '324px', '124px'];

  return (
    <>
      {skeletonHeights.map((height, index) => (
        <StyledSkeletonRectangle
          key={index}
          height={height}
          cornerRadius={cornerRadiusL}
        />
      ))}
    </>
  );
};
