// Used to discriminate client-side routes with regular "page reload" links
// See also: https://brewery.xing.io/link-manager
export const internalPaths = [
  '/ask-xing',
  '/business-solutions',
  '/career-guidance',
  '/chats',
  '/discover',
  '/jobs/[^/]+-\\d+',
  '/jobs/[^/]+-\\d+/contacts',
  '/jobs/apply',
  '/jobs/external-apply',
  '/jobs/find',
  '/jobs/my-jobs',
  '/jobs/quick-apply',
  '/jobs/search',
  '/jobseeker-criteria',
  '/lebenslauf',
  '/network',
  '/network-home',
  '/notifications',
  '/onboarding',
  '/preferences',
  '/premium',
  '/projobs',
  '/settings',
  '/stories',
  '/support/contact',
  '/xingternal',
  '/you',
  '/search/companies',
  '/search/members',
];
