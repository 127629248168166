import type { HeaderLoggedInConfig } from '@xing-com/platform-layout-header-logged-in';

import type { ConfigurationItem, Translate } from '../types';

const NAVIGATION_BADGE_NO_BADGE = 'navigation_badge_no_badge';

export const headerLoggedIn: ConfigurationItem = (
  t: Translate
): HeaderLoggedInConfig => {
  return [
    [
      'NotificationCenter',
      {
        activeItemKey: 'supi',
        notificationKey: 'ncWeb',
        icon: { $component: 'IconNotificationCenter' },
        activeIcon: { $component: 'IconNotificationFilled' },
        href: '/notifications',
        title: t('NOTIFICATION_CENTER_LINK'),
        tracking: {
          sc_o: 'navigation_notifications',
          sc_o_PropActionOrigin: [
            'navigation_badge_notifications',
            NAVIGATION_BADGE_NO_BADGE,
          ],
        },
      },
    ],

    [
      'SettingsDropdownX3',
      {
        icon: { $component: 'IconSettings' },
        title: t('VERTICAL_NAVIGATION_HEADER_PERSONAL_SETTINGS'),
        settingsType: 'x3',
      },
    ],
    [
      'SearchDropdown',
      {
        icon: { $component: 'IconWithDiscoverBadge' },
        title: t('FRAME_HEADER_SEARCH'),
      },
    ],
    [
      'FeatureCondition',
      {
        children: {
          $meta: [
            [
              'UpsellArea',
              {
                children: {
                  $meta: [
                    [
                      'FeatureCondition',
                      {
                        children: {
                          $meta: [
                            [
                              'Button',
                              {
                                variant: 'proJobs',
                                size: 'small',
                                'data-qa': 'upsell-button',
                                href: '/purchase/projobs?reagent=uplt_277&sc_o=navigation_projobs_cta',
                                children: t('FRAME_HEADER_UPSELL_PROJOBS'),
                              },
                            ],
                          ],
                        },
                        elseCase: {
                          $meta: [
                            [
                              'Button',
                              {
                                variant: 'premium',
                                size: 'small',
                                'data-qa': 'upsell-button',
                                href: '/purchase/premium?reagent=uplt_95&sc_o=navigation_premium_cta',
                                children: t('FRAME_HEADER_UPSELL_PREMIUM'),
                              },
                            ],
                          ],
                        },
                        flags: 'showJobSeekerBoneyardUpsellHint',
                      },
                    ],
                  ],
                },
              },
            ],
          ],
        },
        flags: 'showUpsellHint',
      },
    ],
  ];
};
