import { useEffect, useRef, type FC } from 'react';
import styled from 'styled-components';

import { useActivityCenterTracking } from '@xing-com/crate-activity-center-tracking';
import {
  useActivityCenterTotal,
  useIsActivityCenterVisible,
  useSetActivityCenterVisible,
} from '@xing-com/hub';
import type { LinkProps } from '@xing-com/link';
import { Link } from '@xing-com/link';
import { xdlColorText } from '@xing-com/tokens';

import { SuperEllipseWithBadge } from './super-ellipse-with-badge';

type ActivityCenterButtonProps = {
  title: string;
  href?: string;
};

export const ActivityCenterButton: FC<ActivityCenterButtonProps> = ({
  title,
  href,
}) => {
  const total = useActivityCenterTotal();
  const setActivityCenterVisible = useSetActivityCenterVisible();
  const isActivityCenterVisible = useIsActivityCenterVisible();
  const isActivityCenterAvailable = setActivityCenterVisible.length > 0;
  const { trackInteraction } = useActivityCenterTracking();

  const justClosedRef = useRef<boolean>(false);

  useEffect(() => {
    if (isActivityCenterVisible) return;

    justClosedRef.current = true;

    const timerId = setTimeout(() => {
      justClosedRef.current = false;
    }, 100);

    return () => {
      clearTimeout(timerId);
      justClosedRef.current = false;
    };
  }, [isActivityCenterVisible]);

  return (
    <StyledLink
      id="activity-center-button"
      data-testid="frame-vnav-activity-center-button"
      to={href}
      aria-label={title}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (isActivityCenterAvailable) {
          event.preventDefault();
        }

        if (!justClosedRef.current) {
          setActivityCenterVisible(!isActivityCenterVisible);
        }

        trackInteraction({
          event: 'clicked',
          element: 'activitycenter',
          elementDetail: 'activitycenter_open',
        });
      }}
    >
      <SuperEllipseWithBadge count={total} />
    </StyledLink>
  );
};

const StyledLink = styled(Link)<LinkProps>`
  color: ${xdlColorText};
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: none;
  }

  position: relative;
`;
