import { useCallback, useEffect, useRef, useState } from 'react';

import type { WebSocketFunctions } from '@xing-com/crate-core-websocket';
import type { Host } from '@xing-com/crate-xinglet';

type Message<T extends string> = {
  type: T;
  [key: string]: unknown;
};

export interface UseWebhookResult {
  onConnect(callback: () => void): void;
  listen<T extends string>(
    messageType: T,
    handler: (message: Message<T>) => void
  ): Promise<void>;
  send(type: string, message: Record<string, unknown>): Promise<void>;
}

function useIsMounted(): () => boolean {
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return () => mounted.current;
}

function useCallOnCleanList(): (() => void)[] {
  const functionList = useRef<(() => void)[]>([]);
  useEffect(() => {
    const list = functionList.current;
    return list.length
      ? () => list.forEach((callback) => callback())
      : undefined;
  }, []);

  return functionList.current;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useListenersCache(): Map<string, (message: Message<any>) => void> {
  const [listeners] = useState(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new Map<string, (message: Message<any>) => void>()
  );

  return listeners;
}

export function useWebSocket(host: Host<WebSocketFunctions>): UseWebhookResult {
  const mounted = useIsMounted();
  const subscriptions = useCallOnCleanList();
  const listenersCache = useListenersCache();

  const hasOnConnect = useRef(false);
  const onConnect = useRef<(() => void) | undefined>();

  const calledOncePerMessageType = new Set();

  const onConnectFn = useCallback(
    async (callback: () => void) => {
      if (!hasOnConnect.current) {
        hasOnConnect.current = true;

        const unsubscribe = await host.executeCommand(
          '@xing-com/crate-core-websocket.onConnect',
          () => onConnect.current?.()
        );
        subscriptions.push(unsubscribe);
      }

      onConnect.current = callback;
    },
    [host, subscriptions]
  );

  const listenFn = async <T extends string>(
    messageType: T,
    handler: (message: Message<T>) => void
  ): Promise<void> => {
    if (!mounted()) {
      throw new Error('Component already unmounted');
    }

    if (!listenersCache.has(messageType)) {
      listenersCache.set(messageType, handler);
      const unsubscribe = await host.executeCommand(
        '@xing-com/crate-core-websocket.listen',
        messageType,
        (message) => {
          const handler = listenersCache.get(messageType);
          if (handler) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            handler(message as Message<T>);
          }
        }
      );

      subscriptions.push(unsubscribe);
    }

    if (calledOncePerMessageType.has(messageType)) {
      throw new Error(
        `Listener for message type '${messageType}' already registered`
      );
    }
    calledOncePerMessageType.add(messageType);
  };

  const sendFn = async (
    type: string,
    message: Record<string, unknown>
  ): Promise<void> => {
    await host.executeCommand(
      '@xing-com/crate-core-websocket.send',
      type,
      message
    );
  };

  return {
    onConnect: onConnectFn,
    listen: listenFn,
    send: sendFn,
  };
}
