import * as React from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';

export type PageViewTrackerProps = { page?: string; sentBy?: string };

export const PageViewTracker: React.VFC<PageViewTrackerProps> = ({
  page,
  sentBy,
}) => {
  const { track } = useTracking();

  React.useEffect(() => {
    if (page && sentBy) {
      track({
        type: 'nwt',
        event: 'viewed_screen',
        eventSchema: 'basic',
        page,
        sentBy,
      });
    }
  }, [page, sentBy, track]);

  return <></>;
};
