import type { FC } from 'react';
import { useEffect } from 'react';

// styles implemented according to integration guide
// https://new-work.atlassian.net/wiki/spaces/CMNW/pages/528449691/Implementation+guide

export const LightColorscheme: FC = () => {
  const bgColor = '#ffffff';
  const primaryColor = '#C6F16D';
  const acceptBtnBorderColor = '#C6F16D';
  const acceptBtnFontColor = '#1D2124';
  const acceptBtnHoverBgColor = '#A7E770';
  const acceptBtnHoverBgBorderColor = '#A7E770';
  const customBtnColor = '#DBE2E6';
  const customBtnBorderColor = '#DBE2E6';
  const customBtnFontColor = '#1D2124';
  const customBtnHoverBgColor = '#BCC9D1';
  const customBtnHoverBgBorderColor = '#BCC9D1';
  const saveBtnColor = '#DBE2E6';
  const saveBtnBorderColor = '#DBE2E6';
  const saveBtnFontColor = '#1D2124';
  const saveBtnHoverBgColor = '#BCC9D1';
  const saveBtnHoverBgBorderColor = '#BCC9D1';
  const secondLayerTabColor = '#004673';
  const secondLayerTabBorderColor = '#004673';
  const activeToggleFillColor = '#004673';
  const fontfamily = 'Xing Sans, helvetica, arial, sans-serif';
  const fontcolor = '#1D2124';
  const anchorlinkColor = '#0698A0';
  const accentColor = '#DBE2E6';

  const customStyle = `
    :host(#usercentrics-root) div[data-testid="uc-buttons-container"], :host(#usercentrics-root) div[data-testid="first-line-buttons"] {justify-content: flex-end;}
    :host(#usercentrics-root) button[data-testid="uc-save-button"], :host(#usercentrics-root) div[data-testid="uc-customize-button"] {
        flex: 0;
        white-space: pre;
        cursor: pointer;
        font-weight: bold;
        ${fontfamily ? 'font-family: ' + fontfamily + ';' : ''}
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
        border: 1px solid #000000;
        line-height: 1.25rem;
        font-size: 16px;
        height: 2.5rem;
        padding: 0.3125rem 1.5rem;
    }
    :host(#usercentrics-root) button[data-testid="uc-save-button"] {

        white-space: pre;
        cursor: pointer;
        font-weight: bold;
        ${fontfamily ? 'font-family: ' + fontfamily + ';' : ''}
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
        ${saveBtnColor ? 'background-color: ' + saveBtnColor + ';' : ''}
        ${saveBtnFontColor ? 'color: ' + saveBtnFontColor + ';' : ''}
        border: 1px solid ${saveBtnBorderColor};
        line-height: 1.25rem;
        font-size: 16px;
        height: 2.5rem;
        padding: 0.3125rem 1.5rem;
    }
    :host(#usercentrics-root) button[data-testid="uc-save-button"]:active, :host(#usercentrics-root) button[data-testid="uc-save-button"]:hover {
        background-color: ${saveBtnHoverBgColor};
        border: 1px solid ${saveBtnHoverBgBorderColor};
    }
    :host(#usercentrics-root) button[data-testid="uc-accept-all-button"] {

        white-space: pre;
        cursor: pointer;
        font-weight: bold;
        ${fontfamily ? 'font-family: ' + fontfamily + ';' : ''}
        ${primaryColor ? 'background-color: ' + primaryColor + ';' : ''}
        ${acceptBtnFontColor ? 'color: ' + acceptBtnFontColor + ';' : ''}
        border: 1px solid ${acceptBtnBorderColor};
        line-height: 1.25rem;
        font-size: 16px;
        height: 2.5rem;
        padding: 0.3125rem 1.5rem;
    }
    :host(#usercentrics-root) button[data-testid="uc-accept-all-button"]:active, :host(#usercentrics-root) button[data-testid="uc-accept-all-button"]:hover {
        background-color: ${acceptBtnHoverBgColor};
        border: 1px solid ${acceptBtnHoverBgBorderColor};
    }
    :host(#usercentrics-root) button[data-testid="uc-customize-button"] {

        white-space: pre;
        cursor: pointer;
        font-weight: bold;
        ${fontfamily ? 'font-family: ' + fontfamily + ';' : ''}
        ${customBtnColor ? 'background-color: ' + customBtnColor + ';' : ''}
        ${customBtnFontColor ? 'color: ' + customBtnFontColor + ';' : ''}
        border: 1px solid ${customBtnBorderColor};
        line-height: 1.25rem;
        font-size: 16px;
        height: 2.5rem;
        padding: 0.3125rem 1.5rem;
    }
    :host(#usercentrics-root) button[data-testid="uc-customize-button"]:active, :host(#usercentrics-root) button[data-testid="uc-customize-button"]:hover {
        background-color: ${customBtnHoverBgColor};
        border: 1px solid ${customBtnHoverBgBorderColor};
    }

    :host(#usercentrics-root) #uc-tab-list {
        border-bottom: none;
        padding-bottom: .5em;
    }
    :host(#usercentrics-root) #uc-tab-list button {
        border-bottom-width: 0.25rem;
        width: auto;
        border-bottom: 0.25rem solid transparent;
        height: 2.5rem;
    }
    :host(#usercentrics-root) #uc-tab-list button:first-child { margin-right: 1rem }
    :host(#usercentrics-root) #uc-tab-list button[aria-selected="true"] {
        ${secondLayerTabBorderColor ? 'border-bottom-color: ' + secondLayerTabBorderColor + ';' : ''}
        ${secondLayerTabColor ? 'color: ' + secondLayerTabColor + ';' : ''}
    }
    :host(#usercentrics-root) #uc-tab-list button[aria-selected="false"]:hover { border-bottom: 0.25rem solid rgb(180, 180, 180) }
    :host(#usercentrics-root) div[data-testid="uc-heading-title"] {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) div[data-testid="uc-headinger-wrapper"] {
        padding: 0;
    }
    :host(#usercentrics-root) a, :host(#usercentrics-root), button[data-testid="uc-customize-anchor"] {
        text-decoration: underline;
        ${anchorlinkColor ? 'color: ' + anchorlinkColor + ';' : ''}
    }
    :host(#usercentrics-root) #uc-center-container {
        margin: 15px auto 0;
        padding: 0.5rem 0.5rem 1rem 0.5rem;
        max-width: 750px;
        width: 75%;
        background-color: ${bgColor} !important;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) div[data-testid^="uc-tab-"] {
        height: 2.5rem;
    }
    :host(#usercentrics-root) div[data-testid="uc-virtual-list"], :host(#usercentrics-root) div[data-testid="uc-footer"], :host(#usercentrics-root) div[data-testid="uc-header"], :host(#usercentrics-root) div[data-testid="uc-container"], :host(#usercentrics-root) div[data-testid="uc-vertical-scroller"], :host(#usercentrics-root) div[data-testid="uc-fading-wrapper"] {
        background-color: ${bgColor} !important;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) .sc-jxOSlx.cYPgfe, :host(#usercentrics-root) .sc-eBMEME.kYXrjW {
        background-color: ${bgColor} !important;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) div[data-testid="uc-message-container"] {
        background-color: ${bgColor} !important;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) div[role="heading"] {
        background-color: ${bgColor} !important;
        ${fontcolor ? 'color: ' + fontcolor + ';' : ''}
    }
    :host(#usercentrics-root) div[data-testid="uc-tcf-categories"] {
        border-bottom:1px solid ${accentColor};
    }
    :host(#usercentrics-root) button[data-testid="uc-tab-vendors"]{
        background-color: ${bgColor} !important;
        color: ${fontcolor} !important;
    }
    :host(#usercentrics-root) #uc-tab-list button[aria-selected="true"] {
      border-bottom-color: ${fontcolor};
    }
    :host(#usercentrics-root) .kYXrjW {
        border-top:1px solid ${accentColor};
    }
`;

  useEffect(() => {
    const cb: MutationCallback = (mutations, observer) => {
      const usercentricsRoot =
        document.querySelector<HTMLDivElement>('#usercentrics-root');

      if (usercentricsRoot) {
        observer.disconnect();

        const styleElement = document.createElement('style');
        styleElement.innerHTML = customStyle;

        try {
          // Use assertion here to ensure it's defined
          const shadowRoot = usercentricsRoot.shadowRoot;
          if (shadowRoot) {
            shadowRoot.appendChild(styleElement);
          }
        } catch (e) {
          console.error(e);
        }

        // add mods to display toggles in correct color if changed
        try {
          const sroot =
            usercentricsRoot.shadowRoot?.querySelector<HTMLDivElement>(
              "[data-testid='uc-app-container']"
            );
          const cb2: MutationCallback = () => {
            // Attempt to change colors
            try {
              if (activeToggleFillColor) {
                const paths = sroot?.querySelectorAll(
                  "button[aria-disabled='false'][aria-checked='true'] svg > g > g > g > path:nth-child(1)"
                ) as NodeListOf<SVGPathElement>;
                paths.forEach((path) =>
                  path.setAttribute('fill', activeToggleFillColor)
                );
              }
            } catch (e) {
              console.error(e);
            }

            // remove UC Branding
            try {
              usercentricsRoot.shadowRoot
                ?.querySelector('a[href*="usercentrics.com"]')
                ?.closest('div')
                ?.remove();
            } catch (e) {
              console.error(e);
            }
          };

          const obs2 = new MutationObserver(cb2);
          if (sroot) {
            obs2.observe(sroot, obsOptions);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    const target = document.querySelector('html');
    const obsOptions: MutationObserverInit = {
      childList: true,
      attributes: false,
      subtree: true,
    };
    const obs = new MutationObserver(cb);
    if (target) {
      obs.observe(target, obsOptions);
    }

    return () => {
      obs.disconnect();
    };
  }, []);

  return null;
};
