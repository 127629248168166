import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Button } from '@xing-com/button';

const StyledUpsellButton = styled(Button)`
  white-space: nowrap;
`;

type BoneyardProps = {
  dataTestId?: string;
  size: 'small' | 'medium' | 'large';
  variant: 'primary' | 'secondary' | 'tertiary';
  href?: string;
};

export const Boneyard: React.FC<PropsWithChildren<BoneyardProps>> = ({
  dataTestId,
  size,
  variant,
  href,
  children,
}) => {
  return (
    <StyledUpsellButton
      data-testid={dataTestId}
      size={size}
      variant={variant}
      href={href}
    >
      {children}
    </StyledUpsellButton>
  );
};
