import type { ComponentProps, FC } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useMetaConfig } from '@xing-com/hub';
import { MetaRenderer } from '@xing-com/meta-renderer';
import type { ConfigForResolvers } from '@xing-com/meta-renderer';
import { commonMetaRendererResolvers } from '@xing-com/platform-layout-common-meta-renderer-resolvers';
import { Me } from '@xing-com/platform-layout-me';
import { addParamsWithConditions } from '@xing-com/xing-tracking';

import { createHelpLink } from './create-help-link';
import { LinkItem } from './link-item';
import postLogoutUser from './post-logout-user';
import { StyledSettingsDropdownList } from './settings-dropdown-list.styles';

export type SettingsDropdownListProps = {
  closeModal: () => void;
  helpLink?: string;
};

export type SettingsDropdownItemProps = {
  closeModal: () => void;
  helpLink: string;
  link: {
    key: string;
    href: string;
    title: string;
    icon: ComponentProps<typeof LinkItem>['icon'];
    color: ComponentProps<typeof LinkItem>['color'];
    tracking: Record<string, string | [string, string]>;
  };
};

const SettingsDropdownItem: FC<SettingsDropdownItemProps> = ({
  closeModal,
  helpLink,
  link,
}) => {
  const { key, tracking, ...linkProps } = link;
  const { locale } = useIntl();
  const { pathname } = useLocation();

  // Some links are special:
  const { href, ...linkPropsWithExceptions } =
    key === 'logoutPersonal'
      ? {
          ...linkProps,
          onClick: postLogoutUser,
        }
      : key === 'helpPersonal'
        ? {
            ...linkProps,
            target: '_blank',
            rel: 'noopener noreferrer',
            href: helpLink || createHelpLink(locale, pathname),
          }
        : linkProps;

  const completeTrackingUrl = addParamsWithConditions(href, tracking, {});

  return (
    <LinkItem
      data-testid={`SettingsDropdown-list-item-${key}`}
      closeModal={closeModal}
      {...linkPropsWithExceptions}
      to={completeTrackingUrl}
    />
  );
};

const settingsDropdownResolvers = {
  SettingsDropdownItem,
  SettingsDropdownMe: Me,
};

const resolvers = {
  ...commonMetaRendererResolvers,
  ...settingsDropdownResolvers,
};

export type SettingsDropdownConfig = ConfigForResolvers<typeof resolvers>;

export const SettingsDropdownList: FC<SettingsDropdownListProps> = ({
  closeModal,
  helpLink,
}) => {
  const { data } = useMetaConfig();

  return (
    <StyledSettingsDropdownList>
      {data?.navigation?.settingsDropdown && (
        <MetaRenderer
          config={data.navigation.settingsDropdown}
          resolvers={resolvers}
          closeModal={closeModal}
          helpLink={helpLink}
        />
      )}
    </StyledSettingsDropdownList>
  );
};
