import * as React from 'react';

import * as Styled from './interactive-flag.styles';
import type { InteractiveFlagProps } from './interactive-flag.types';

export const InteractiveFlag = React.forwardRef<
  HTMLSpanElement,
  InteractiveFlagProps
>(
  (
    { className, onClick, show = false, variant, ...props },
    forwardedRef
  ): JSX.Element => (
    <Styled.InteractiveFlag
      aria-expanded={show}
      className={className}
      data-xds="InteractiveFlag"
      icon={
        <Styled.Icon $show={show} width={18} height={18} aria-hidden="true" />
      }
      onClick={onClick}
      ref={forwardedRef}
      role="button"
      size="medium"
      tabIndex={0}
      variant={variant}
      {...props}
    />
  )
);

InteractiveFlag.displayName = 'InteractiveFlag';
