import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { SendWebConsentDataDocument } from './send-web-consent-mutation.gql-types';

type EventDetail = {
  source: 'none' | 'button' | 'first' | 'second' | 'embeddings' | '__ucCmp';
  type:
    | 'CMP_SHOWN'
    | 'ACCEPT_ALL'
    | 'DENY_ALL'
    | 'SAVE'
    | 'MORE_INFORMATION_LINK'
    | 'IMPRINT_LINK'
    | 'PRIVACY_POLICY_LINK';
};

type ServiceBaseInfo = {
  id: string;
  name: string;
  consent: {
    status: string;
  };
};

type TcfVendor = {
  id: string;
  name: string;
  consent: boolean;
};

// Once a user clicks on the "Accept all" or "Deny all" button in the Usercentrics UI,
// the consent data is sent to the backend.
export const useUsercentricsEvent = (): void => {
  const [sendWebConsent] = useMutation(SendWebConsentDataDocument);

  useEffect(() => {
    const handleUCUICMPEvent = (event: CustomEvent<EventDetail>): void => {
      const type = event?.detail?.type;
      const payload = {
        channel: 'web',
        domain: window.location.origin,
        // @ts-expect-error UC_UI
        controllerId: UC_UI.getControllerId(),

        nonTcfVendors: JSON.stringify(
          // @ts-expect-error UC_UI
          (UC_UI.getServicesBaseInfo() as ServiceBaseInfo[]).map(
            ({ id, name, consent: { status } }) => ({
              id,
              name,
              consent: { status },
            })
          )
        ),

        tcfVendors: JSON.stringify(
          // @ts-expect-error UC_UI
          (UC_UI.getTCFVendors() as TcfVendor).map(({ id, name, consent }) => ({
            id,
            name,
            consent,
          }))
        ),
      };

      switch (type) {
        case 'SAVE':
        case 'DENY_ALL':
        case 'ACCEPT_ALL':
          console.log('sending web consent data');
          sendWebConsent({ variables: { input: payload } });
          break;
        default:
          break;
      }
    };

    window.addEventListener(
      'UC_UI_CMP_EVENT' as keyof WindowEventMap,
      handleUCUICMPEvent as EventListener
    );

    return () => {
      window.removeEventListener(
        'UC_UI_CMP_EVENT' as keyof WindowEventMap,
        handleUCUICMPEvent as EventListener
      );
    };
  }, []);
};
