import * as React from 'react';
import { useIntl } from 'react-intl';

import devNote from '@xing-com/dev-note';

import * as Styled from './flag.styles';
import { flagVariantText } from './flag.text-mapping';
import type { FlagProps } from './flag.types';

export const Flag = React.forwardRef<HTMLSpanElement, FlagProps>(
  (
    {
      className,
      gender = 'neutral',
      icon,
      innerRef,
      onClick,
      show = true,
      size = 'medium',
      sizeConfined,
      sizeWide,
      variant,
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const isInteractive = Boolean(onClick);

    // TODO: remove deprecated variants (deprecated 16.02.2024)
    const deprecatedVariants = [
      'proCoach',
      'proTrainer',
      'moderator',
      'executive',
      'ambassador',
      'proBusiness',
    ];

    if (deprecatedVariants.includes(variant)) {
      devNote.deprecate(`The '${variant}' variant is deprecated.`);
    }

    if (variant === 'new') {
      devNote.deprecate(
        `The 'new' variant is deprecated, please use Marker (strong) instead`
      );
    }

    if (variant === 'beta') {
      devNote.deprecate(
        `The 'beta' variant is deprecated, please use Marker instead`
      );
    }

    return (
      <React.Fragment>
        {show ? (
          <Styled.Flag
            $size={size}
            $sizeConfined={sizeConfined}
            $sizeWide={sizeWide}
            $variant={variant}
            className={className}
            data-xds="Flag"
            onClick={onClick}
            onKeyDown={
              isInteractive
                ? (e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      onClick?.();
                    }
                  }
                : undefined
            }
            ref={innerRef || forwardedRef}
            role={isInteractive ? 'button' : undefined}
            tabIndex={isInteractive ? 0 : undefined}
            {...props}
          >
            <Styled.Text
              $variant={variant}
              fontWeight="bold"
              forwardedAs="span"
              noMargin
              size="xsmall"
            >
              {intl.formatMessage({ id: flagVariantText[variant][gender] })}
              {icon}
            </Styled.Text>
          </Styled.Flag>
        ) : null}
      </React.Fragment>
    );
  }
);

Flag.displayName = 'Flag';
