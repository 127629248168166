import styled from 'styled-components';

import {
  scale005,
  scale010,
  scale020,
  scale050,
  scale100,
  xdlColorProgressBadgeBackground01,
  xdlColorProgressBadgeBackground02,
  xdlColorProgressBadgeBackground03,
  xdlColorProgressBadgeBackground04,
  xdlColorTextOnDark,
  xdlColorTextOnLight,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

type ProgressBadgeTProps = {
  $progress: number;
};

// *** Components ***
export const ProgressBadge = styled.span<ProgressBadgeTProps>`
  background-color: ${({ $progress }) => {
    if ($progress <= 39) return xdlColorProgressBadgeBackground01;
    if ($progress <= 79) return xdlColorProgressBadgeBackground02;
    if ($progress <= 99) return xdlColorProgressBadgeBackground03;
    return xdlColorProgressBadgeBackground04;
  }};
  border-color: ${xdlColorTextOnDark};
  border-radius: ${scale050};
  border-style: solid;
  border-width: ${scale010};
  display: inline-flex;
  flex-shrink: 0;
  height: ${scale100};
  padding-inline-end: ${scale020};
  padding-inline-start: ${scale020};
`;

export const Text = styled(BodyCopy)`
  color: ${xdlColorTextOnLight};
  inset-block-start: -${scale005};
  position: relative;
`;
