import type * as React from 'react';
import styled from 'styled-components';

import { Marker } from '@xing-com/marker';
import type { MarkerVariant } from '@xing-com/marker';
import { SingleSelect } from '@xing-com/menu';
import { scale090, spaceS, scale070 } from '@xing-com/tokens';
import { addParams } from '@xing-com/xing-tracking';

export type SearchDropdownItemProps = {
  itemKey: string;
  href: string;
  title: string;
  icon: React.ElementType;
  marker?: { text: string; variant: MarkerVariant };
  tracking: Record<string, string>;
};

const StyledSingleSelect = styled(SingleSelect)`
  font-size: ${scale070};
  width: 100%;

  div,
  span {
    width: 100%;
  }
`;

const StyledIcon = styled.svg`
  flex: 0 0 ${scale090};
  height: ${scale090};
  width: ${scale090};
  margin-right: ${spaceS};
`;

const StyledMarker = styled(Marker)`
  margin: 0 ${spaceS};
  flex-shrink: 4;
`;

const StyledSpan = styled.span`
  display: flex;
`;

export const SearchDropdownItem: React.VFC<SearchDropdownItemProps> = ({
  itemKey,
  href,
  title,
  icon: IconElement,
  marker,
  tracking,
}) => {
  const completeTrackingUrl = addParams(href, tracking);

  return (
    <StyledSingleSelect
      data-testid={`nav-search-${itemKey}`}
      to={completeTrackingUrl}
    >
      <StyledIcon as={IconElement} />
      <StyledSpan>
        <span>{title}</span>
        {marker && (
          <StyledMarker variant={marker.variant}>{marker.text}</StyledMarker>
        )}
      </StyledSpan>
    </StyledSingleSelect>
  );
};
