import { useQuery } from '@apollo/client';
import type { PropsWithChildren } from 'react';

import { BottomBarEnabledModule } from '@xing-com/bottom-bar-enabled-module';
import { BrowserBarOpenModule } from '@xing-com/browser-bar-open-module';
import { ActivityCenterModule } from '@xing-com/crate-activity-center-module';
import { CrateFrameConfigModule } from '@xing-com/crate-communication-frame-config-module';
import { ReachLinkModule } from '@xing-com/crate-communication-reach-link-module';
import { captureException } from '@xing-com/crate-xinglet';
import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';
import { FeatureSwitchesModule } from '@xing-com/feature-switches-module';
import type { LoginState } from '@xing-com/hub';
import { asyncResult, ErrorHandlerContext } from '@xing-com/hub';
import { ViewerDataModule } from '@xing-com/initial-data-module';
import { NotificationsModule } from '@xing-com/notifications-module';
import { OmViewModule } from '@xing-com/om-view-module';
import { OmViewAnimation } from '@xing-com/platform-layout-om-view-animation';
import { SplitkitModule } from '@xing-com/splitkit';
import { ThemingModule } from '@xing-com/theming-module';
import { VisitorIdModule } from '@xing-com/visitor-id-module';

import { FeatureSwitchesQueryDocument } from './feature-switches.gql-types';

type StaticHubProps = PropsWithChildren;

function StaticHub({ children }: StaticHubProps): JSX.Element {
  const { activeThemeCookie, visitorId } = useRuntimeConfig();

  return (
    <CrateFrameConfigModule>
      <ThemingModule activeThemeCookie={activeThemeCookie}>
        <ReachLinkModule>
          <VisitorIdModule
            visitorId={visitorId ?? new Date().getTime().toString()}
          >
            <SplitkitModule>
              <BrowserBarOpenModule>
                <BottomBarEnabledModule>
                  <OmViewModule>
                    <OmViewAnimation>{children}</OmViewAnimation>
                  </OmViewModule>
                </BottomBarEnabledModule>
              </BrowserBarOpenModule>
            </SplitkitModule>
          </VisitorIdModule>
        </ReachLinkModule>
      </ThemingModule>
    </CrateFrameConfigModule>
  );
}

export type LoggedOutHubProps = StaticHubProps & {
  enableActivityCenter: boolean;
};

export function LoggedOutHub({
  children,
  enableActivityCenter,
}: LoggedOutHubProps): JSX.Element {
  return (
    <ErrorHandlerContext.Provider value={captureException}>
      <ViewerDataModule loginState={'LOGGED_OUT'}>
        <StaticHub>
          {enableActivityCenter ? (
            <ActivityCenterModule>{children}</ActivityCenterModule>
          ) : (
            children
          )}
        </StaticHub>
      </ViewerDataModule>
    </ErrorHandlerContext.Provider>
  );
}

export type LoggedInState = Exclude<LoginState, 'LOGGED_OUT'>;

export type LoggedInHubProps = StaticHubProps & {
  loginState: LoggedInState;
};

export function LoggedInHub({
  children,
  loginState,
}: LoggedInHubProps): JSX.Element {
  const { loading, data } = useQuery(FeatureSwitchesQueryDocument, {
    errorPolicy: 'all',
  });
  const { __typename, ...features } = data?.viewer?.features ?? {};
  const featureSwitches = data?.viewer?.featureSwitches ?? undefined;

  return (
    <ErrorHandlerContext.Provider value={captureException}>
      <ViewerDataModule loginState={loginState}>
        <FeatureSwitchesModule
          features={asyncResult(loading ? undefined : features)}
          featureSwitches={asyncResult(featureSwitches)}
        >
          <NotificationsModule pollInterval={60_000}>
            <StaticHub>{children}</StaticHub>
          </NotificationsModule>
        </FeatureSwitchesModule>
      </ViewerDataModule>
    </ErrorHandlerContext.Provider>
  );
}
